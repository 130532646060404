import React from "react"
import { Container } from "reactstrap"
import Header from "./Header"
import About from "./About"
import Experience from "./Experience"
import Projects from "./Projects"
import Education from "./Education"
import Skills from "./Skills"
import Footer from "./Footer"

export default function Portfolio()  {
    return (
        <>
            <Header />
            <Container className="main" fluid>
                <About />
                <Experience />
                <Projects />
                <Education />
                <Skills />
            </Container>
            <Container className="footer" fluid>
                <Footer />
            </Container>
        </>
    )
}
