import React from "react"
import LocalizeHelper from "../../helpers/localizeHelper"

class Languages extends React.Component {
    constructor(props) {
        super(props)

        this.changeLanguage = this.changeLanguage.bind(this)
    }

    changeLanguage(lang) {
        LocalizeHelper.setLanguage(lang)
        window.location.reload()
    }

    render() {
        return (
            <div>
                <i onClick={(e) => {this.changeLanguage("ru")}} className="locale-ru"></i>
                <i onClick={(e) => {this.changeLanguage("en")}} className="locale-us"></i>
            </div>
        )
    }
}

export default Languages;