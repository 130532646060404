
const strings = {
    en: {
        personName: "Michael Farberov",
        personTitle: "Full-Stack Engineer",
        personSubtitle: "Team Lead",
        aboutTextP1: `I’m a <span class="highlight">Full-Stack Engineer</span> and Team Lead with more than 10 years of experience in the telecommunication industry.`,
        aboutTextP2: `I have a great experience of building solutions from system design to delivery, maintenance, and performance tuning.
            My core value in work is "think twice, and deliver fast while staying flexible". 
            Most important is continuous improvement, and reaching common ground with both quality and business value.
            I brought this idea through more than 10 of finished <a href="#projects">projects</a>.`,
        aboutTextP3: `What excites me most is the ability to work on high-load projects and having a meaningful impact on building solutions.
            Currently, I'm primarily focused on full-stack development with React and Java. See my <a href="#skills">#stack</a> for more details.
            If you have a project we can collaborate on please don't hesitate to let me know. See my <a href="#contacts">#contacts</a> in the top-right corner.`,
        experience: "Experience",
        projects: "Projects",
        education: "Education",
        skills: "Skills",
        cv: "CV",
        footerText: "I'm available for work, get in touch",
        experienceText: "I worked over 10 years in large international companies in the telecommunication industry.",
        timelineNow: "Now",
        timelineNowText: `<strong>Currently open to co-operation on new and existing projects.</strong><br />
        <a href="#contacts">Contact me</a>. I will answer you in 24 hours.`,
        accomplishments: "Accomplishments",
        technologies: "Technologies",
        projectsText: `In the same time I worked on my side projects. Here are some of them that are still online. For more examples see my <a href="https://github.com/mikhailfarberov" target="_blank">Github</a>.`,
        skillsText: "This is my current technology stack I use to build projects.",
        educationAndCert: "Education and Certifications",
        educationDegree: "Master degree of Computer Science and Information Security",
        verify: "verify",
        teamLeader: "Team Lead",
        techlead: "Tech Lead",
        spb: "Saint-Petersburg, Russia",
        georgia: "Tbilisi, Georgia",
        seniorDev: "Senior Software Engineer",
        dev: "Software Engineer",
        present: "Present",
        accomlishments1: "Designed and developed from scratch data migration software and pipeline for business transformation project in Megafon.",
        accomlishments2: "Was a product owner and took responsibility for a product development roadmap.",
        accomlishments3: "Implemented Kanban methodology in the team.",
        accomlishments4: "Contributed to implementation of CI/CD practices.",
        accomlishments5: "Mentored the team.",
        accomlishments6: "Was awarded by customer's CEO for significant impact on project's success.",
        accomlishments7: "Was responsible for ETL pipelines while migrating data to company's solution in Rostelecom (North-West macroregion)",
        accomlishments8: "Made performace audit and consulting of billing system",
        accomlishments9: "Made customers support on performance issues with company's products",
        accomlishments10: "Worked in an international team with Germany",
        accomlishments11: "Mantained one of resource inventory systems for Deutche Telekom",
        accomlishments12: "Learned German from the beginning to B1 level in 6 months",
        accomlishments13: "Lead two cross-functional teams in the computer vision field",
        accomlishments14: "Optimized development processes in a team by implementing CI/CD best practices and quality gates",
        accomlishments15: "Suggested system design changes that allow simplifying the architectural landscape and reusing components",
        accomlishments16: "Streamlined the massive refactoring process by developing several Python microservices from scratch",
        accomlishments17: "Led cross-platform team for development of a messenger for Android, iOS, and Web platforms",
        accomlishments18: "Made system design decisions to optimise cost of development and support for a project with huge legacy code base",
        accomlishments19: "Implemented integration with Camunda to automate company's business processes",
        accomlishments20: "Built microservices to integrate the messenger with other systems of the organization",
        githubButton: "View on Github",
        soonButton: "Coming soon",
        gplayButton: "Try on GooglePlay",
        privateButton: "Private access",
        teleparserText: "A simple utility to get and reuse content from Telegram channels, VK and Instagram pages",
        forestbountyText: `A game for Android and iOS combining two mechanics - collecting resources and finding patterns. 
            More than 50k of users in less than 2 months after release.
            My responsibilities are backend and infrastructure.`,
        casualheroesText: `A game for Android, iOS and social networks (VK, Odnoklassniki, Mail, Facebook).
            Create clans and battle online for virtual currency.
            More than 100k of users.
            My responsibilities were backend and infrastructure.`,
        mushroomersText: `A game for Android, iOS and social networks (VK, Odnoklassniki, Mail, Facebook).
            Find mushrooms in the forest and cook with recipes to earn virtual currency.
            More than 500k of users.
            My responsibilities were backend and infrastructure.`,
        time2postText: `A service of media planning and scheduled posts for VK.COM, Instagram, Telegram and Viber.
            Former time2post.com. Currently it has not public access.`,
        frontend: "Frontend",
        backend: "Backend",
        databases: "Databases",
        infrastructure: "Infrastructure",
        cicd: "CI/CD",
    },
    ru: {
        personName: "Михаил Фарберов",
        personTitle: "Full-Stack инженер",
        personSubtitle: "Тим лид",
        aboutTextP1: `<span class="highlight">Full-Stack инженер</span> и тим-лид с более чем 10 летним опытом работы.`,
        aboutTextP2: `За это время успел поработать в крупных международных компаниях в индустрии телекоммуникаций и занимался как проектированием архитектуры решения, так и разработкой, внедрением и последующей поддержкой.
            Мои ключевые ценности в работе можно сформулировать одним предложением - "проектируй внимательно, внедряй быстро, улучшай постоянно". 
            Другими словами я всегда за выверенный баланс между качеством и скоростью внедрения, деталями и бизнес-ценностью.
            Я всегда следую этой идее в моей работы и в моих собственных <a href="#projects">проектах</a>.`,
        aboutTextP3: `Больше всего меня вдохновляет разработка высоко-нагруженных приложений и возможность внести значимый вклад в реализацию.
            На текущий момент мои интересы лежат в области Full-Stack разработки, преимущественно с использованием React и Java. Ниже перечислены технологии, входящие в мой <a href="#skills">#техстек</a> .
            Я всегда открыт новым возможностям и всегда на связи, если у Вас есть интересные проекты, в которых мы можем сотрудничать. Мои <a href="#contacts">#контакты</a> для связи в правом вернем углу.`,
        experience: "Опыт",
        projects: "Проекты",
        education: "Образование",
        skills: "Скиллы",
        cv: "Резюме",
        footerText: "Я вседа на связи",
        experienceText: "В течение последних 10 лет я работаю в крупных международных компаниях в сфере телекоммуникаций.",
        timelineNow: "Сейчас",
        timelineNowText: `<strong>Открыт для сотрудничества.</strong><br />
        <a href="#contacts">Свяжитесь</a> со мной и я отвечу в течение 24 часов.`,
        accomplishments: "Достижения",
        technologies: "Технологии",
        projectsText: `В то же время я работаю над собственными проектами. Опубликованные проекты перечислены ниже. Еще больше примеров в моем <a href="https://github.com/mikhailfarberov" target="_blank">Github</a>.`,
        skillsText: "Мой текущий техстек, который  я использую в работе.",
        educationAndCert: "Образование и сертификаты",
        educationDegree: "Специалист в области информационных технологии и информационной безопасности",
        verify: "проверить",
        teamLeader: "Тим лид",
        techlead: "Руководитель направления / Тех лид",
        spb: "Санкт-Петербург",
        georgia: "Тбилиси, Грузия",
        seniorDev: "Старший инженер-программист",
        dev: "Инженер-программист",
        present: "н.в.",
        accomlishments1: "Спроектировал и разработал с нуля продукт для миграции данных в биллинговое решение в рамках проекта трансформации бизнеса в Мегафон.",
        accomlishments2: "Был владельцем продукта и отвечал за сбор требований  и развитие.",
        accomlishments3: "Внедрил методологию Kanban в команде.",
        accomlishments4: "Внес вклад в применение техник CI/CD в рамках проекта.",
        accomlishments5: "Был наставником и лидером для команды.",
        accomlishments6: "Был отмечен багодарностями со стороны руководства Заказчика за значительный вклад в реализацию проекта.",
        accomlishments7: "Отвечал за разработку методов переноса данных при внедрении биллингового решения в Ростелеком.",
        accomlishments8: "Выполнял аудит производительности биллинговых систем.",
        accomlishments9: "Участвовал в поддержке Заказчика по вопросам эксплуатации и прозводительности продуктов компании.",
        accomlishments10: "Работал в междуранодной команде Россия-Германия.",
        accomlishments11: "Выполнял доработку системы логических ресурсов для Deutche Telekom.",
        accomlishments12: "Изучил немецкий с нуля до уровня B1 за 6 месяцев.",
        accomlishments13: "Участие в управлении двумя кросс-функциональными командами в области компьютерного зрения в роли технического лидера.",
        accomlishments14: "Оптимизировал процесс разработки в команде путем внедрения практик CI/CD и внутреннего контроля качества (ревью кода, линтеры, покрытие юнит тестами).",
        accomlishments15: "Предложил архитектурные изменения, позволившие упростить архитектурный ландшафт и повысить переиспользование компонентов.",
        accomlishments16: "Ускорил архитектурные преобразования и масштабный рефакторинг кода, самостоятельно переработав и разработав с нуля часть микросервисов.",
        accomlishments17: "Руководил кросс-функциональной командой разработки корпоративного мессенджера для Android, iOS и Web",
        accomlishments18: "Принял решения по оптимизации стоимости разработки и поддержки программного продукта",
        accomlishments19: "Выполнил интеграцию с Camunda с целью автоматизации бизнес-процессов организации",
        accomlishments20: "Разработал микросервисы для интеграции мессенджера с другими ИТ системами организации",
        githubButton: "Смотреть на Github",
        soonButton: "Скоро",
        gplayButton: "Установить в GooglePlay",
        privateButton: "Закрытый доступ",
        teleparserText: "Сервис для сбора и переиспользвания контента из Телеграм каналов и страниц ВК, Инстаграм",
        forestbountyText: `Игра для Android и iOS сочетающая две механики - сбор ресурсов и нахождение паттернов. 
            Более чем 50 тыс пользователей менее чем за 2 месяца после релиза.
            Я отвечал за бэкенд и инфраструктуру.`,
        casualheroesText: `Игра для Android, iOS и социальных сетей (ВК, Одноклассники, Mail, Facebook).
            Cоздание кланов и онлайн битвы с другими игроками.
            Более чем 100 тыс пользователей.
            Я отвечал за бэкенд и инфраструктуру.`,
        mushroomersText: `Игра для Android, iOS и социальных сетей (ВК, Одноклассники, Mail, Facebook).
            Сбор грибов в лесу и приготовление блюд по рецептам.
            Более чем 500 тыс пользователей.
            Я отвечал за бэкенд и инфраструктуру.`,
        time2postText: `Сервис медиа планирования и отложенной публикации контента для ВК, Инстаграм, Телеграм и Viber.
            Бывший time2post.com. Сейчас находится в закрытом доступе`,
        frontend: "Фронт",
        backend: "Бэк",
        databases: "Базы данных",
        infrastructure: "Инфраструктура",
        cicd: "CI/CD",
    }
}

export default strings

