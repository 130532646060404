import React from "react"
import { Row, Col } from "reactstrap"
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Footer() {
    return (
        <Row id="contacts">
            <Col className="centered" sm={{ size: 8, offset: 2 }}>
                <h3>{LocalizeHelper.getLocString("footerText")}</h3>
                <a href="https://www.linkedin.com/in/mikhailfarberov/" target="_blank" title="Go to LinkedIn profile"><i className="fab fa-linkedin" /></a>
                <a href="https://github.com/mikhailfarberov" target="_blank" title="Go to Github profile"><i className="fab fa-github-square" /></a>
                <a href="https://www.facebook.com/mikhail.farberov" target="_blank" title="Go to Facebook page"><i className="fab fa-facebook-square" /></a>
                <a href="mailto:mail@itworks.pw" title="Email me"><i className="fas fa-envelope" /></a>
                <br />
                <a href="https://itworks.pw/" target="_blank">
                    itworks.pw
                </a>
                <br />
                Built with &hearts; and React
            </Col>
        </Row>
    )
}
