import React from "react"
import { Row, Col, CardDeck, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap"
import ReactHtmlParser from "react-html-parser"
import { projects } from "./config";
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Projects()  {
    let projectsContent = projects.map((section) => {
        let sectionContent = section.map((item, index) => {
            return (section.length < 3) ? (
                <Col sm={(index == 0) ? { size: 5, offset: 1 }:{ size: 5}}>
                    <Card>
                        <div className="img-container">
                            <CardImg top width="100%" src={item.image} alt={item.title} />
                        </div>
                        <CardBody>
                            <CardTitle>{item.title}</CardTitle>
                            <CardSubtitle>{item.year}</CardSubtitle>
                            <CardText>
                                {item.description}
                            </CardText>
                            <div className="tags">
                                {item.tags}
                            </div>
                            <div className="centered">
                                {(item.link.url) ? 
                                    (
                                    <Button size="sm" color="success" href={item.link.url} target="_blank">{item.link.text}</Button>
                                    ):(
                                    <Button size="sm" color="success" disabled>{item.link.text}</Button>
                                    )
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ):(
                <Card>
                    <div className="img-container">
                        <CardImg top width="100%" src={item.image} alt={item.title} />
                    </div>
                    <CardBody>
                        <CardTitle>{item.title}</CardTitle>
                        <CardSubtitle>{item.year}</CardSubtitle>
                        <CardText>
                            {item.description}
                        </CardText>
                        <div className="tags">
                            {item.tags}
                        </div>
                        <div className="centered">
                            {(item.link.url) ? 
                                (
                                <Button size="sm" color="success" href={item.link.url} target="_blank">{item.link.text}</Button>
                                ):(
                                <Button size="sm" color="success" disabled>{item.link.text}</Button>
                                )
                            }
                        </div>
                    </CardBody>
                </Card>
            )
        })
        return (
            <CardDeck>
                {sectionContent}
            </CardDeck>
        )
    })
    return (
        <Row id="projects">
            <Col sm={{ size: 8, offset: 2 }}>
                <h2>{LocalizeHelper.getLocString("projects")}</h2>
                <p className="section-descr">
                    {ReactHtmlParser(LocalizeHelper.getLocString("projectsText"))}
                </p>
                {projectsContent}
            </Col>
        </Row>
    )
}
