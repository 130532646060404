import React from "react"
import { Row, Col } from "reactstrap"
import { education } from "./config"
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Education() {
    let educationContent = education.map((item) => {
        return (
            <li>
                {(item.imageClass) ? (<img className={item.imageClass} src={item.image} />):(<img src={item.image} />)}
                <br />
                <b>{item.title}</b>
                {(item.link) ? (
                    <>
                        <br />
                        <a href={item.link}>{LocalizeHelper.getLocString("verify")}</a>
                    </>):null}
                <p>{item.year}</p>
            </li>
        )
    })
    return (
        <Row id="education">
            <Col sm={{ size: 8, offset: 2 }}>
                <h2>{LocalizeHelper.getLocString("educationAndCert")}</h2>
                <ul className="horizontal">
                    {educationContent}
                </ul>
            </Col>
        </Row>
    )
}
