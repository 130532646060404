import React from "react"
import ReactHtmlParser from 'react-html-parser'; 
import LocalizeHelper  from "../../helpers/localizeHelper"

export var person = {
    "name": LocalizeHelper.getLocString("personName"),
    "title": LocalizeHelper.getLocString("personTitle"),
    "subtitle": LocalizeHelper.getLocString("personSubtitle"),
    "image": "/avatar.jpg",
    "link": {"text": "itworks.pw", "url": "https://itworks.pw"},
    "text": (
        <>
            <p>
                {ReactHtmlParser(LocalizeHelper.getLocString("aboutTextP1"))}
            </p>
            <p>
                {ReactHtmlParser(LocalizeHelper.getLocString("aboutTextP2"))}
            </p>
            <p>
                {ReactHtmlParser(LocalizeHelper.getLocString("aboutTextP3"))}
            </p>
        </>
    )
}

export var education = [
    {
        "title": LocalizeHelper.getLocString("educationDegree"),
        "image": "assets/polytech-logo-en-big.svg", 
        "year": "2009"
    },
    {
        "title": "Cambridge Business English Certificate Higher (C1)",
        "image": "assets/bec-logo.svg", 
        "year": "2014"
    },
    {
        "title": "Oracle PL/SQL Developer Certified Professional",
        "image": "assets/oracle-ocp.png", 
        "imageClass": "squared",
        "year": "2009",
        "link": "https://www.youracclaim.com/badges/b92ed2f6-c357-463a-98a5-536bb4c68405/linked_in_profile"
    },
    {
        "title": "Oracle GoldenGate Certified Implementation Specialist",
        "image": "assets/oracle-oce.png", 
        "imageClass": "squared",
        "year": "2015"
    },
    {
        "title": "AWS Certified Solutions Architect - Associate",
        "image": "assets/aws.png", 
        "imageClass": "squared",
        "year": "2021"
    }
];

export var experience = [
    {
        "title": LocalizeHelper.getLocString("techlead"),
        "company": "Arrival",
        "location": LocalizeHelper.getLocString("georgia"),
        "year": `2021 - ${LocalizeHelper.getLocString("present")}`,
        "accomplishments": [
            LocalizeHelper.getLocString("accomlishments17"),
            LocalizeHelper.getLocString("accomlishments18"),
            LocalizeHelper.getLocString("accomlishments19"),
            LocalizeHelper.getLocString("accomlishments20")
        ],
        "technologies": [
            "Java",
            "Spring",
            "Kotlin",
            "React.JS",
            "Typescript",
            "Kafka",
            "Kubernetes",
            "AWS",
            "Gitlab CI"
        ]
    },
    {
        "title": LocalizeHelper.getLocString("techlead"),
        "company": "Nedra - New Digital Resources for Assets",
        "location": LocalizeHelper.getLocString("spb"),
        "year": `2020 - 2021`,
        "accomplishments": [
            LocalizeHelper.getLocString("accomlishments13"),
            LocalizeHelper.getLocString("accomlishments14"),
            LocalizeHelper.getLocString("accomlishments15"),
            LocalizeHelper.getLocString("accomlishments16"),
        ],
        "technologies": [
            "Python (FastAPI, SqlAlchemy, AsyncIO, Pytest)",
            "React.js",
            "Node.js",
            "Kafka",
            "Docker",
            "Jenkins",
            "Atlassian Jira",
            "Atlassian Bitbucket"
        ]
    },
    {
        "title": LocalizeHelper.getLocString("teamLeader"),
        "company": "Nexign",
        "location": LocalizeHelper.getLocString("spb"),
        "year": "2016 - 2020",
        "accomplishments": [
            LocalizeHelper.getLocString("accomlishments1"),
            LocalizeHelper.getLocString("accomlishments2"),
            LocalizeHelper.getLocString("accomlishments3"),
            LocalizeHelper.getLocString("accomlishments4"),
            LocalizeHelper.getLocString("accomlishments5"),
            LocalizeHelper.getLocString("accomlishments6"),
        ],
        "technologies": [
            "Oracle Database SQL and PL/SQL",
            "Python for Backend",
            "React + Node + Swagger + ORM + Docker for UI",
            "RabbitMQ",
            "Teamcity",
            "Atlassian Jira",
            "Atlassian Bitbucket"
        ]
    },
    {
        "title": LocalizeHelper.getLocString("seniorDev"),
        "company": "Nexign",
        "location": LocalizeHelper.getLocString("spb"),
        "year": "2010 - 2016",
        "accomplishments": [
            LocalizeHelper.getLocString("accomlishments7"),
            LocalizeHelper.getLocString("accomlishments8"),
            LocalizeHelper.getLocString("accomlishments9"),
        ],
        "technologies": [
            "Oracle Database SQL and PL/SQL",
            "Oracle GoldenGate"
        ]
    },
    {
        "title": LocalizeHelper.getLocString("dev"),
        "company": "T-Systems",
        "location": LocalizeHelper.getLocString("spb"),
        "year": "2008 - 2010",
        "accomplishments": [
            LocalizeHelper.getLocString("accomlishments10"),
            LocalizeHelper.getLocString("accomlishments11"),
            LocalizeHelper.getLocString("accomlishments12"),
        ],
        "technologies": [
            "Oracle Database SQL and PL/SQL",
            "Oracle APEX",
            "Java Spring Framework"
        ]
    },
];

export var projects = [
    [
        {
            "title": "Teleparser",
            "image": "/assets/projects/teleparser.png",
            "year": "2020",
            "description": LocalizeHelper.getLocString("teleparserText"),
            "tags": "#React #Redux #Python #Flask #PHP #Sqlite #Docker",
            "link": {"text": LocalizeHelper.getLocString("githubButton"), "url": "https://github.com/mikhailfarberov/teleparser"}
        },
        {
            "title": "Forest Bounty",
            "image": "/assets/projects/forestbounty.jpg",
            "year": "2020",
            "description": LocalizeHelper.getLocString("forestbountyText"),
            "tags": "#PHP #Mysql #Node #Websockets #Nginx #Kafka #Linux",
            "link": {"text": LocalizeHelper.getLocString("gplayButton"), "url": "https://play.google.com/store/apps/details?id=air.ru.vigr.forestbounty"}
        }
    ],
    [
        {
            "title": "Casual Heroes",
            "image": "/assets/projects/casualheroes.jpg",
            "year": "2017",
            "description": LocalizeHelper.getLocString("casualheroesText"),
            "tags": "#PHP #Mysql #Node #Websockets #Nginx #Firebase #RabbitMQ #Linux",
            "link": {"text": LocalizeHelper.getLocString("gplayButton"), "url": "https://play.google.com/store/apps/details?id=air.ru.vigr.heroes"}
        },
        {
            "title": "Mushroomers: Forest Clans",
            "image": "/assets/projects/forestclans.png",
            "year": "2014",
            "description": LocalizeHelper.getLocString("mushroomersText"),
            "tags": "#PHP #Mysql #Node #Websockets #Nginx #Linux",
            "link": {"text": LocalizeHelper.getLocString("gplayButton"), "url": "https://play.google.com/store/apps/details?id=air.ru.vigr.mushroomers2"}
        },
        {
            "title": "Time2Post",
            "image": "/assets/projects/time2post.png",
            "year": "2014",
            "description": LocalizeHelper.getLocString("time2postText"),
            "tags": "#PHP #Mysql #MongoDB #Nginx #Linux",
            "link": {"text": LocalizeHelper.getLocString("privateButton")}
        }
    ]
];          
                
export var skills = [
    {
        "title": LocalizeHelper.getLocString("frontend"),
        "items": [
            {
                "title": "React",
                "image": "assets/react.png"
            },
            {
                "title": "Typescript",
                "image": "assets/typescript.png"
            },
            {
                "title": "jQuery",
                "image": "assets/jquery.png"
            },
            {
                "title": "HTML",
                "image": "assets/html5.png"
            },
            {
                "title": "CSS",
                "image": "assets/css3.png"
            },
            {
                "title": "SASS",
                "image": "assets/sass.png"
            },
            {
                "title": "Bootstrap",
                "image": "assets/bootstrap.png"
            }
        ]
    },
    {
        "title": LocalizeHelper.getLocString("backend"),
        "items": [
            {
                "title": "Java Spring",
                "image": "assets/spring.png"
            },
            {
                "title": "Kotlin",
                "image": "assets/kotlin.jpeg"
            },
            {
                "title": "Node",
                "image": "assets/nodejs.png"
            },
            {
                "title": "REST",
                "image": "assets/rest.png"
            },
            {
                "title": "GraphQL",
                "image": "assets/graphql.png"
            },
            {
                "title": "gRPC",
                "image": "assets/grpc.png"
            },
            {
                "title": "Swagger",
                "image": "assets/swagger.png"
            },
        ]
    },
    {
        "title": LocalizeHelper.getLocString("databases"),
        "items": [
            {
                "title": "Oracle Database",
                "image": "assets/oracle.png"
            },
            {
                "title": "Postgres",
                "image": "assets/postgres.png"
            },
            {
                "title": "Mysql",
                "image": "assets/mysql.png"
            },
            {
                "title": "MongoDB",
                "image": "assets/mongodb.png"
            },
            {
                "title": "Redis",
                "image": "assets/redis.png"
            }
        ]
    },
    {
        "title": LocalizeHelper.getLocString("infrastructure"),
        "items": [
            {
                "title": "AWS",
                "image": "assets/aws-logo.png"
            },
            {
                "title": "Kubernetes",
                "image": "assets/k8s.png"
            },
            {
                "title": "Linux",
                "image": "assets/linux.jpeg"
            },
            {
                "title": "RabbitMQ",
                "image": "assets/rabbitmq.png"
            },
            {
                "title": "Kafka",
                "image": "assets/kafka.png"
            },
            {
                "title": "Nginx",
                "image": "assets/nginx.png"
            }
        ]
    },
    {
        "title": LocalizeHelper.getLocString("cicd"),
        "items": [

            {
                "title": "Gitlab",
                "image": "assets/gitlab.png"
            },
            {
                "title": "Teamcity",
                "image": "assets/teamcity.png"
            },
            {
                "title": "Webpack",
                "image": "assets/webpack.png"
            },
            {
                "title": "Docker",
                "image": "assets/docker.png"
            },
            {
                "title": "Ansible",
                "image": "assets/ansible.png"
            }
        ]
    }
];
