import React from "react"
import { Row, Col } from "reactstrap"
import Languages from "./Languages"
import { person } from "./config"

export default function About()  {
    return (
        <Row>
            <Col className="centered" sm="4">
                <img src={person.image} className="profile-image" />
                <h1>{person.name}</h1>
                <h3>{person.title}</h3>
                <h5>{person.subtitle}</h5>
                <div>
                    <a href={person.link.url} className="" target="_blank">
                        {person.link.text}
                    </a>
                </div>
                <Languages />
            </Col>
            <Col className="about-text" sm="6">
                {person.text}
            </Col>
        </Row>
    )
}