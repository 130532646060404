import React from "react"
import { Row, Col } from "reactstrap"
import ReactHtmlParser from "react-html-parser"
import { experience } from "./config"
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Experience() {
    let experienceContent = experience.map((item) => {
        let accomplishments = item.accomplishments.map((text) => {
            return (
                <li>{text}</li>
            )
        })
        let technologies = item.technologies.map((text) => {
            return (
                <li>{text}</li>
            )
        })
        return (
            <li className="timeline_element timeline_element--now project">
                <div className="timeline_element-date">
                    <time className="timeline_element-date-text">{item.year}</time>
                </div>
                <div className="timeline_element-contents">
                    <div className="project-text">
                        <div className="project-description">
                            <h5>{item.title} @ {item.company}</h5>
                            <i>{item.location}</i>
                            <br />
                            <b>{LocalizeHelper.getLocString("accomplishments")}</b>
                            <ul>
                                {accomplishments}
                            </ul>
                            <b>{LocalizeHelper.getLocString("technologies")}</b>
                            <ul>
                                {technologies}
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        )
    })
    return (
        <Row id="experience">
            <Col sm={{ size: 8, offset: 2 }}>
                <h2>{LocalizeHelper.getLocString("experience")}</h2>
                <p className="section-descr">
                    {LocalizeHelper.getLocString("experienceText")}
                </p>
                <ul className="timeline">
                    <li className="timeline_element timeline_element--now project">
                        <div className="timeline_element-date">
                            <time className="timeline_element-date-text">{LocalizeHelper.getLocString("timelineNow")}</time>
                        </div>
                        <div className="timeline_element-contents">
                            <div className="project-text">
                                <div className="project-description">
                                    {ReactHtmlParser(LocalizeHelper.getLocString("timelineNowText"))}
                                </div>
                            </div>
                        </div>
                    </li>
                    {experienceContent}
                </ul>
            </Col>
        </Row>
    )
}
