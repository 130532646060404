import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/styles.css";

import Portfolio from "./components/Portfolio/Portfolio";

ReactDOM.render(
  <Portfolio />,
  document.getElementById("root")
);
