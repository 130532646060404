import React, { useEffect, useState } from "react"
import { Navbar, Nav, NavItem, NavLink, NavbarBrand, NavbarText, NavbarToggler, Collapse } from "reactstrap"
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [isScrolled, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const scrollIntoSection = (e) => {
        e.preventDefault();

        document.querySelector(e.target.getAttribute("href")).scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <Navbar color="success" className={isScrolled ? "active":""} dark expand="lg">
            <NavbarToggler onClick={toggle} className="mr-2" />
            <NavbarBrand href="/">{LocalizeHelper.getLocString("personName")}</NavbarBrand>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <NavLink href="#experience" onClick={scrollIntoSection}>{LocalizeHelper.getLocString("experience")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#projects" onClick={scrollIntoSection}>{LocalizeHelper.getLocString("projects")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#education" onClick={scrollIntoSection}>{LocalizeHelper.getLocString("education")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#skills" onClick={scrollIntoSection}>{LocalizeHelper.getLocString("skills")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href={`assets/MichaelFarberov.CV.2023.${LocalizeHelper.getCurrentLanguage()}.pdf`} target="_blank">{LocalizeHelper.getLocString("cv")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://cal.com/mikhailfarberov" target="_blank" className="btn">Check out my availability</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
            <NavbarText>
                <a href="https://www.linkedin.com/in/mikhailfarberov/" target="_blank" title="Go to LinkedIn profile"><i className="fab fa-linkedin" /></a>
                <a href="https://github.com/mikhailfarberov" target="_blank" title="Go to Github profile"><i className="fab fa-github-square" /></a>
                <a href="https://www.facebook.com/mikhail.farberov" target="_blank" title="Go to Facebook page"><i className="fab fa-facebook-square" /></a>
                <a href="mailto:mail@itworks.pw" title="Email me"><i className="fas fa-envelope" /></a>
            </NavbarText>
        </Navbar>
    )
}
