import React from "react"
import { Row, Col } from "reactstrap"
import { skills } from "./config"
import LocalizeHelper from "../../helpers/localizeHelper"

export default function Skills() {
    let skillsList = skills.map((section) => {
        let sectionContent = section.items.map((skill) => {
            return (
                <li>
                    <img className="squared" src={skill.image} />
                    <br />
                    <b>{skill.title}</b>
                </li>
            )
        })
        return (
            <>
                <h5>{section.title}</h5>
                <ul className="horizontal">
                    {sectionContent}
                </ul>
            </>
        )
    })
    return (
        <Row id="skills">
            <Col sm={{ size: 8, offset: 2 }}>
                <h2>{LocalizeHelper.getLocString("skills")}</h2>
                <p className="section-descr">
                    {LocalizeHelper.getLocString("skillsText")}
                </p>
                {skillsList}
            </Col>
        </Row>
    )
}